import '../../stylesheets/components/modal/modal.scss';
import Cross from "../../assets/icons/cross.svg";

function Modal(props) {
  return (
    <div
      className="customModal"
      onClick={event => {
        if (event.target === event.currentTarget) {
          props.setIsVisible(false);
        }
      }}
    >
      <div className="modal-container">
        <div
          onClick={() => props.setIsVisible(false)}
          className="modalX">
          <img src={Cross} alt="" />
        </div>
        <div className={"titleContainer"} style={ {marginBottom: 10}} >
          <div className="title">{props.title}</div>
        </div>
        {props.content}
      </div>

    </div>
  );
}

export default Modal;
