import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
// import AsyncLocalStorage from '@createnextapp/async-local-storage';
// import { toast } from 'react-toastify';
// import api from '../Lib/api';
import "../stylesheets/components/auth/auth.scss";
import logo from '../assets/icons/logo-silueta.svg';
import eye from '../assets/icons/eye.svg';
import noEye from '../assets/icons/eye-no.svg';
import LittleSpinner from "../components/Utils/LittleSpinner";

const schema = yup.object().shape({
  name: yup.string().max(20, "Tu nombre puede ser de máximo 20 carácteres")
    .required("Debes escribir un nombre"),
  surname: yup.string().max(41, "Tu apellido debe ser de máximo 41 carácteres")
    .required("Debes escribir tus apellidos"),
  email: yup.string().email("Debes escribir un correo válido").required("Debes escribir un correo"),
  password: yup.string().required("Debes escribir una contraseña")
    .min(process.env.REACT_APP_PASSWORD_MIN_LENGTH,
      `La contraseña debe de tener al menos ${process.env.REACT_APP_PASSWORD_MIN_LENGTH} carácteres`)
});

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
  })
);

const Signup = () => {

  const classes = useStyles();
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });
  const [showPw, setShowPw] = useState(false);
  // const [showRpPw, setShowRpPw] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    setLoading(true)
    // api.signup(data);
  };
  return (
    <section className="megacontainer">
      <section className="authformcont">
        <form
          className="authform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img className="auth-image" src={logo} alt="logo" />
          <h1 className="auth-main">Hola, bienvenid@ a Klasea</h1>
          <div className="from-group mt-3">
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={(e) => props.onChange(e)}
                  id={
                    errors.title ? "outlined-error-helper-text" : "outlined-basic"
                  }
                  label="Nombre"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.name}
                  helperText={errors.name && errors.name.message}
                />
              )}
            />
          </div>
          <div className="from-group mt-3 mb-0">
            <Controller
              name="surname"
              defaultValue=""
              control={control}
              render={(props) => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={(e) => props.onChange(e)}
                  id={
                    errors.title ? "outlined-error-helper-text" : "outlined-basic"
                  }
                  label="Apellidos"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.surname}
                  helperText={errors.surname && errors.surname.message}
                />
              )}
            />
          </div>
          <div className="form-group mt-3 mb-0">
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  id="outlined-basic"
                  label="Correo electrónico"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </div>
          <div className="form-group mt-3">
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  type={showPw ? "text" : "password"}
                  id="outlined-basic"
                  label="Contraseña"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.password}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon onClick={() => setShowPw(prev => !prev)}>
                          <img src={showPw ? eye : noEye} alt="Button to show the password." />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="form-group mt-2 mb-0">
            {loading ? (
              <div className="spinnercont">
                <LittleSpinner color="white" />
              </div>
            ) : (
                <input
                  type="submit"
                  className="authbtn loginbtn"
                  value="Crear cuenta"
                />
              )}
          </div>
          <div className="form-group nombottom">
            <Link to="/login">
              <small className="form-text text-center text-muted mt-2">
                ¿Ya tienes una cuenta?
                <span className="registerbtn ml-1">Entrar</span>
              </small>
            </Link>
          </div>
        </form>
      </section>
    </section>
  );
};

export default Signup;
