import "../../stylesheets/components/groupicon/groupicon.scss";

function GroupIcon(props) {
    return (
        <div className="groupIcon" style={{background:props.groupColor}}>
          <span>{props.groupName?.substring(0, 2)}</span>
        </div>
    )
}

export default GroupIcon
