import { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom"; 
import "../../stylesheets/navs/navBar.scss";

export const GroupIcon = withRouter(props => {
  const groupName = props.title;
  const [group, setGroup] = useState("");

  useEffect(() => {
    setGroup(groupName.substring(0,2));
    //const colors = ["#A5F0BF", "#A5F0EB", "#8CE3FA"];
    //const random_color = colors[Math.floor(Math.random() * colors.length)];
    //setColor(random_color);
  }, [groupName]);
  return (
    <NavLink to={`/groups/${props.groupId}`} className={props.className} style={{ backgroundColor: props.color }}>
      {group}
    </NavLink>
  );
});
