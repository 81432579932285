import "../../stylesheets/components/groupsModal/groupsmodal.scss";
import TopBar from "../NavBars/TopBar";
import GroupsContainer from "./GroupsContainer";

function GroupsModal() {

    return (
        <div className="groupsmobilemodal">
            <TopBar />
            <div className="groupsModalCont">
                <h1 className="modalgrouptitle ">Mis grupos</h1>
                <GroupsContainer />
            </div> 
        </div>
    )
}

export default GroupsModal
