
const NotFound = props => {
    return (
        <div>
            Error!
        </div>
    )
}

export default NotFound;
