//Assets
import logoutI from "../../assets/icons/log-out.svg";

//Own Libraries
import api from "../../Lib/api";

//3rd Party Integrations
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import {
    Menu,
    Item
} from "react-contexify";
import "../../stylesheets/libraries/react-contexify/main.scss";

function NavBarModal() {

    const logOut = async () => {
        try {
            const body = {}
            body["refreshToken"] = await AsyncLocalStorage.getItem('refreshToken');
            await AsyncLocalStorage.removeMultiple(['accessToken', 'refreshToken']);
            await api.logout(body);
            window.location.replace('/');
        } catch (e) {
            console.error(e);
        }
    }

    async function handleItemClick({ event}) {
        event.stopPropagation();
        await logOut();
    }

    return (
        <Menu id="logout" className="navbarPopup">
            <Item id="delete-item" className="userOption" onClick={handleItemClick}>
                <span>Cerrar Sesión</span>
                <img src={logoutI} alt="" />
            </Item>
        </Menu>
        // <div className="navbarPopup">
        //     {/* <div className="userOption ">
        //         <span>Ajustes</span>
        //         <img src={SettingsActive} alt="" />
        //     </div> */}
        //     <div onClick={logOut} className="userOption">
                // <span>Cerrar Sesión</span>
                // <img src={logout} alt="" />
        //     </div>
        // </div>
    )
}

export default NavBarModal
