import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import { toast } from 'react-toastify';
import api from '../Lib/api';
import "../stylesheets/components/auth/auth.scss";
import logo from '../assets/icons/logo-silueta.svg';
import eye from '../assets/icons/eye.svg';
import noEye from '../assets/icons/eye-no.svg';
import LittleSpinner from "../components/Utils/LittleSpinner";


const schema = yup.object().shape({
  email: yup.string().email("Debes escribir un correo válido").required("Debes escribir un correo"),
  password: yup.string().required("Debes escribir una contraseña")
  .min(process.env.REACT_APP_PASSWORD_MIN_LENGTH, 
    `La contraseña debe de tener al menos ${process.env.REACT_APP_PASSWORD_MIN_LENGTH} carácteres`)
});

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
  })
);

const Login = () => {
  const classes = useStyles();
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });
  const [showPw, setShowPw] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async data => {
    try {
      setLoading(true);
      const res = await api.login(data);
      const { accessToken, refreshToken } = res.data;
      await AsyncLocalStorage.setMultiple([{ accessToken }, { refreshToken }]);
      window.location.reload();
    } catch (e) {
      toast.error(
        'El usuario introducido no existe o la contraseña està mal. Intentelo de nuevo.'
      );
    }
    setLoading(false);
  };
  return (
    <div className="megacontainer">
      <div className="authformcont">
        <form
          className="authform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img className="auth-image" src={logo} alt="logo" />
          <h1 className="auth-main">¡Hola, bienvenido de nuevo!</h1>
          <div className="form-group mt-2">
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  id="outlined-basic"
                  label="Correo electrónico"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </div>
          <div className="form-group mt-0">
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  type={showPw ? "text" : "password"}
                  id="outlined-basic"
                  label="Contraseña"
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.password}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon onClick={() => setShowPw(prev => !prev)}>
                          <img src={showPw ? eye : noEye} alt="Button to show the password." />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Link to="/forgotpw">
              <small className="form-text text-muted">
                ¿Has olvidado tu contraseña?
              </small>
            </Link>
          </div>
          <div className="form-group mt-2 mb-0">
            {loading ? (
               <div className="spinnercont">
               <LittleSpinner color="white" />
             </div>
            ):(
              <input
              type="submit"
              className="authbtn loginbtn"
              value="Iniciar Sessión"
            />
            )}
          </div>
          {/* <div className="form-group nombottom">
            <Link to="/signup">
              <small className="form-text text-center text-muted mt-2">
                ¿No tienes una cuenta?
                <span className="registerbtn ml-1">Registrate</span>
              </small>
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
