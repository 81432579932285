import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  makeStyles,
  createStyles,
  TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import api from '../Lib/api';
import "../stylesheets/components/auth/auth.scss";
import logo from '../assets/icons/logo-silueta.svg';
import LittleSpinner from "../components/Utils/LittleSpinner";
import SucessMessage from "../components/Forms/messages/SucessMessage";
import ErrorMessage from "../components/Forms/messages/ErrorMsg";

const schema = yup.object().shape({
  email: yup.string().email().required()
});

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
  })
);

const Forgot = () => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async data => {
    try {
      setLoading(true);
      await api.forgot(data);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="megacontainer">
      <div className="authformcont">
        <form
          className="authform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img className="auth-image" src={logo} alt="logo" />
          <h1 className="auth-main">Escribe tu correo para resetear tu contraseña</h1>
          <div className="form-group mt-3">
            <Controller
              name="email"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => {
                    setSuccess(false)
                    setError(false)
                    props.onChange(e)
                  }}
                  id="outlined-basic"
                  label="Correo electrónico"
                  variant="outlined"
                  className={classes.formControl}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </div>
          <div className="form-group mt-2 mb-0">
            {loading ? (
              <div className="spinnercont">
                <LittleSpinner color="white" />
              </div>
            ) : (
                <input
                  type="submit"
                  className="authbtn loginbtn"
                  value="Resetear contraseña"
                />
              )}
          </div>
        </form>
        {success && <SucessMessage >
          Hemos recibido tu petición. <br /> Ve a tu correo para finalizar el proceso.
          </SucessMessage>}
        {error && <ErrorMessage >
          Algo ha ido mal. Inténtalo de nuevo.
          </ErrorMessage>}
        <Link to="/login">
          <p className="text-muted mt-2">
            Volver al login
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Forgot;
