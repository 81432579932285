import loader from "../../assets/spinners/Gif-2-blue.gif";
import "../../stylesheets/utils/spinner.scss";

function Spinner() {

    return (
            <div className="spinner-cont">
                <img src={loader} alt="spinner" />
            </div>
    )
}

export default Spinner
