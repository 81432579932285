import { MobileNavItems } from "./NavBarItems";
import "../../stylesheets/navs/bottombar.scss";
import { NavLink, withRouter } from "react-router-dom";

const BottomBar = withRouter((props) => {

    const getNavLinkClass = (path) => {
        return props.location.pathname === path ? true : false;
    };

    return (
        <div className="bottombar">
            <div>
                {MobileNavItems.map((item) => {
                    const active = getNavLinkClass(item.path);
                    const icon = active ? item.activeIcon : item.icon;
                    return (
                        <span className="bottomIconCont" key={item.title}>
                            <NavLink
                                className={active ? "active-icon" : "icon"}
                                to={item.path}
                                style={{
                                    backgroundImage: icon,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                }}
                            ></NavLink>
                        </span>
                    );
                })}
            </div>
        </div>
    )
})

export default BottomBar;
