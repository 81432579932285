import { useState, useEffect } from 'react';
import { NavLink, withRouter, Link } from "react-router-dom";
import { useAbilityContext } from "../../Lib/abilityContext";
import { useAppContext } from "../../Lib/appContext";
import Avatar from '@material-ui/core/Avatar';
import { contextMenu } from 'react-contexify';
import AddGroupForm from "../Forms/AddGroupForm";
import Modal from "../Forms/Modal";
import { NavBarItems } from "./NavBarItems";
import { GroupIcon } from "./GroupIcon";
import NavBarModal from "./NavBarModal";

import "../../stylesheets/navs/navBar.scss";
import "../../stylesheets/navs/bottombar.scss";
import logo from "../../assets/logo.png";
import Add from "../../assets/icons/add.svg";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const NavBar = withRouter((props) => {

  const { user, setUser } = useAppContext();
  const ability = useAbilityContext();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [addGroup, setAddGroup] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? true : false;
  };

  const changeState = (e) => {
    contextMenu.show({
      id: "logout",
      event: e,
      position: {
        x: 90,
        y: window.innerHeight - 68
      }
    });

  }
  const updateGroups = (groups) => {
    setUser({ ...user, groups: groups })
  }

  return (
    <>
      {addGroup && (
        <Modal
          setIsVisible={setAddGroup}
          content={
            <AddGroupForm
              setIsVisible={setAddGroup}
              setGroups={updateGroups}
              groups={user.groups}
            />
          }
          title="Añadir Grupo"
        />
      )}
      <div className="nav-bar">
        <Link to="/home">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <ul className="nav-menu-items">
          {NavBarItems.map((item) => {
            const active = getNavLinkClass(item.path);
            const icon = active ? item.activeIcon : item.icon;
            return (
              <div className="navItemCont" key={item.title}>
                <div className="item-container" >
                  <NavLink
                    className={active ? "active-icon" : "icon"}
                    to={item.path}
                    style={{
                      backgroundImage: icon,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></NavLink>
                </div>
                {active && <div className="activeBar"></div>}
              </div>
            );
          })}
        </ul>
        {(windowDimensions.height > 557 && ability.can('create','groups')) && <div
          className="add"
          onClick={() => { setAddGroup(true) }}
          style={{
            cursor: "pointer",
            backgroundImage: `url(${Add})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />}
        <li className="groups">
          {windowDimensions.height > 557 && user.groups.slice(0, (windowDimensions.height > 686 ? 3 : (windowDimensions.height > 628 ? 2 : 1))).map((item) => {
            const active = getNavLinkClass(`/groups/${item._id}`);
            return (
              <div className="navItemContGroup"  key={item._id}>
                <GroupIcon className={active ? "active-group" : "group"} title={item.title} groupId={item._id} color={item.color} />
                {active && <div className="activeBar"></div>}
              </div>
            );
          })}
        </li>
        <hr className="line" />
        <Avatar
          onClick={changeState}
          className="user" style={{ width: "53px", height: "53px" }} src={user.imageUrl} alt={user.fullName} />
        <NavBarModal />
      </div>
    </>
  );
});

export default NavBar;
