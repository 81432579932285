import '../../stylesheets/components/forms/forms.scss';
import api from '../../Lib/api';
import { toast } from 'react-toastify';
import {
  makeStyles,
  InputLabel,
  createStyles,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CirclePicker } from 'react-color';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
    colors: {
      width: '100%',
      marginLeft: 5,
    },
  })
);

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
});

const AddGroupForm = props => {

  const classes = useStyles();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {  // * Improvement: Sería més optim ferho amb un useCallback?¿?
    const getData = async () => {
      try {
        const res = await api.addGroup(data);
        toast.success('Grupo creado!');
        let updatedGroups = [...props.groups, res.data.group];
        props.setGroups(updatedGroups);
      } catch (e) {
        toast.error('Parece que ha habido un error');
        console.error(e);
      }
    };
    getData();
    props.setIsVisible(false);
  };

  return (
    <form className="formCont" onSubmit={handleSubmit(onSubmit)}>
      {/*Aqui hauria de ser un desplegable amb els grup dels que ets admin */}
      <div className="section">
        <Controller
          name="title"
          defaultValue=""
          control={control}
          render={props => (
            <TextField
              {...props}
              value={props.value}
              onChange={e => props.onChange(e)}
              id="outlined-basic"
              label="Nombre del grupo"
              variant="outlined"
              className={classes.formControl}
            />
          )}
        />
      </div>
      <div className="section">
        <Controller
          name="description"
          defaultValue=""
          control={control}
          render={props => (
            <TextField
              {...props}
              value={props.value}
              onChange={e => props.onChange(e)}
              id="outlined-basic"
              label="Descripción"
              variant="outlined"
              className={classes.formControl}
              multiline
            />
          )}
        />
      </div>
      <div className="section">
        <InputLabel id="demo-simple-select-outlined-label">
          Escoge el color del grupo
        </InputLabel>
        {
          <Controller
            name="color"
            defaultValue="#2bbff2"
            control={control}
            render={props => (
              <CirclePicker
                {...props}
                color={props.value}
                onChange={c => props.onChange(c.hex)}
                className={classes.colors}
                colors={[
                  '#2bbff2',
                  '#8ce3fa',
                  '#00e8d1',
                  '#8cf0de',
                  '#78e5a3',
                  '#9BB1FC',
                ]}
              />
            )}
          />
        }
      </div>
      <input className="submit mt-4" type="submit" value="Guardar" />
    </form>
  );
};

export default AddGroupForm;
