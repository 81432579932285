import React from 'react'
import "../../stylesheets/utils/littlespinner.scss";

function LittleSpinner(props) {
    return (
        <div className="lds-ellipsis">
            <div style={{background:props.color}}></div>
            <div style={{background:props.color}}></div>
            <div style={{background:props.color}}></div>
            <div style={{background:props.color}}></div>
        </div>
    )
}

export default LittleSpinner
