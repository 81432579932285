import { useAppContext } from '../../Lib/appContext';
// import AddGroupIcon from "../GroupIcon/AddGroupIcon";
import GroupIcon from "../GroupIcon/GroupIcon";
// import CreateGroup from "../../components/FastActions/CreateGroup";
import { useHistory } from "react-router-dom";
function GroupsContainer() {

    const history = useHistory();
    const { user , gMIsOpen, setGMIsOpen} = useAppContext();

    const groups = user.groups;
    
    const goToGroup = (groupId) => {
        setGMIsOpen(!gMIsOpen)
        history.push(`groups/${groupId}`)
    }
    return (
        <div className="groupsmodalcontainer">
            {/* <div className="groupIconModalCont" onClick={addGroup}>
                <div>
                    <AddGroupIcon />
                </div>
                <span className="groupNameModal">Añadir grupo</span>
            </div> */}
            {groups.map(group => {
                return (
                    <div className="groupIconModalCont" key={"modal" + group._id} onClick={()=> goToGroup(group._id)}>
                        <div>
                            <GroupIcon groupColor={group.color} groupName={group.title} />
                        </div>
                        <span className="groupNameModal">{group.title}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default GroupsContainer
