import axios from 'axios';
import AsyncLocalStorage from '@createnextapp/async-local-storage';

const apiUrl = process.env.REACT_APP_API_URL;

// * Request interceptor to add the auth token header to requests.
axios.interceptors.request.use(
  async config => {
    try {
      const accessToken = await AsyncLocalStorage.getItem('accessToken');
      if (accessToken) {
        config.headers['x-auth-token'] = accessToken;
      }
      return config;
    } catch (e) {
      Promise.reject(e);
    }
  },
  error => {
    Promise.reject(error);
  }
);

// * Response interceptor to refresh token on receiving token expired error.
axios.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    try {
      const refreshToken = await AsyncLocalStorage.getItem('refreshToken');
      if (
        refreshToken &&
        error.response?.status === 401
      ) {
        const getNewToken = await axios.post(`${apiUrl}/auth/refresh_token`, {
          refreshToken: refreshToken,
        });
        if (getNewToken.status === 200) {
          await AsyncLocalStorage.setItem(
            'accessToken',
            getNewToken.data.accessToken
          );
          return axios(originalRequest);
        }
      }
      return Promise.reject(error);
    } catch (e) {
      Promise.reject(e);
    }
  }
);

//functions to make api calls
const api = {
  login: body => {
    return axios.post(`${apiUrl}/auth/login`, body);
  },
  signup: body => {
    return axios.post(`${apiUrl}/auth/signup`, body);
  },
  getUser: () => {
    return axios.get(`${apiUrl}/user`);
  },
  getGroups: () => {
    return axios.get(`${apiUrl}/groups`);
  },
  group: id => {
    return axios.get(`${apiUrl}/groups/${id}`);
  },
  events: date => {
    const parsedDate = new Date(date);
    return axios.get(`${apiUrl}/events?month=${parsedDate.getMonth()}&year=${parsedDate.getFullYear()}`);
  },
  groupEvents : (groupId, date) => {
    return axios.get(`${apiUrl}/events/${groupId}?month=${date.getMonth()}&year=${date.getFullYear()}`);
  },
  getUrlSala: meetingId => {
    return axios.get(`${apiUrl}/meetings/join/${meetingId}`);
  },
  addEvent: (id, body) => {
    return axios.post(`${apiUrl}/events/add/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  addGroup: body => {
    return axios.post(`${apiUrl}/groups/add`, body);
  },
  editGroup: (body, groupId) => {
    return axios.post(`${apiUrl}/groups/update/${groupId}`, body);
  },
  updateUser: body => {
    return axios.post(`${apiUrl}/settings/update`, body);
  },
  updateUserImage: body => {
    return axios.post(`${apiUrl}/settings/uploadprofile`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  checkUserPassword: body => {
    return axios.post(`${apiUrl}/settings/verify-password`, body);
  },
  createInstant: body => {
    return axios.post(`${apiUrl}/meetings/create-instant`, body);
  },
  joinGroup: randomId => {
    return axios.post(`${apiUrl}/participants/new/${randomId}`);
  },
  uploadFilesToSession: (body, id) => {
    return axios.post(`${apiUrl}/files/upload/${id}`, body);
  },
  hideFile: (id) => {
    return axios.post(`${apiUrl}/files/hide/${id}`);
  },
  showFile: (id) => {
    return axios.post(`${apiUrl}/files/show/${id}`);
  },
  deleteFile: (id) => {
    return axios.post(`${apiUrl}/files/delete/${id}`);
  },
  logout: (body) => {
    return axios.post(`${apiUrl}/auth/logout`, body);
  },
  forgot: (body) => {
    return axios.post(`${apiUrl}/auth/forgotpw`, body);
  },
  checkReset: (random) => {
    return axios.get(`${apiUrl}/auth/resetpw/${random}`);
  },
  resetPassword: (random, body) => {
    return axios.post(`${apiUrl}/auth/changepw/${random}`, body);
  },
  getEvent: (id) => {
      return axios.get(`${apiUrl}/events/one/${id}`);
  },
  deleteEvent: id => {
    return axios.post(`${apiUrl}/events/delete/${id}`);
  },
  updateEvent: (id, body) => {
    return axios.post(`${apiUrl}/events/update/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  addTopic: (groupId, body) => {
    return axios.post(`${apiUrl}/foro/topic/add/${groupId}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getTopics: (groupId) => {
    return axios.get(`${apiUrl}/foro/${groupId}`);
  },
  addComment: (topicId, body) => {
    return axios.post(`${apiUrl}/foro/comment/add/${topicId}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  //   refreshToken: body => {
  //     return axios.post(`${apiUrl}/auth/refresh_token`, body);
  //   },
  //   logout: body => {
  //     return axios.delete(`${apiUrl}/auth/logout`, body);
  //   },
  //   getProtected: () => {
  //     return axios.get(`${apiUrl}/protected_resource`);
  //   },
};

export default api;
