//import "../../stylesheets/NavBar.css";
import Home from '../../assets/icons/home.svg';
import Calendar from '../../assets/icons/calendar.svg';
import Settings from '../../assets/icons/settings.svg';

import CalendarActive from '../../assets/icons/calendarActive.svg';
import HomeActive from '../../assets/icons/homeActive.svg';
import SettingsActive from '../../assets/icons/settingsActive.svg';

export const NavBarItems = [
  {
    title: 'Home',
    path: '/home',
    icon: `url(${Home})`,
    activeIcon: `url(${HomeActive})`,
  },
  {
    title: 'Calendar',
    path: '/calendar',
    icon: `url(${Calendar})`,
    activeIcon: `url(${CalendarActive})`,
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: `url(${Settings})`,
    activeIcon: `url(${SettingsActive})`,
  },
];

export const MobileNavItems = [
  {
    title: 'Calendar',
    path: '/calendar',
    icon: `url(${Calendar})`,
    activeIcon: `url(${CalendarActive})`,
  },
  {
    title: 'Home',
    path: '/home',
    icon: `url(${Home})`,
    activeIcon: `url(${HomeActive})`,
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: `url(${Settings})`,
    activeIcon: `url(${SettingsActive})`,
  },
];
