import { useEffect, useState, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import { AppContext } from './Lib/appContext';
import { AbilityContext, ability } from './Lib/abilityContext';
import api from './Lib/api';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
// * Main styles for all the app
import './App.css';
import './stylesheets/utils/buttons.scss';
import "./stylesheets/utils/inputs.scss";
import "./stylesheets/layout.scss";

// * Navigations
import NavBar from './components/NavBars/NavBar';
import TopBar from "./components/NavBars/TopBar";
import BottomBar from "./components/NavBars/Bottombar";

// * Modals
import GroupModal from "./components/GroupsModal/GroupsModal";

// * Other components
import Login from './screens/Login';
import Signup from './screens/Signup';
import Forgot from "./screens/Forgot";
import Reset from "./screens/Reset";
/*
* De moment farem el join amb el panel admin, així que desactivem
* la pantalla que portava la funcionalitat per unirte a un grup.
import JoinGroup from './screens/JoinGroup';
*/ 
import NotFound from './screens/NotFound';
import Spinner from './components/other/Spinner';
import Notification from './components/other/Notification';

// * Protected Screen components (lazy loading)
const Home = lazy(() => import('./screens/Home'));
const Group = lazy(() => import('./screens/Group'));
const Calendar = lazy(() => import('./screens/Calendar'));
const Settings = lazy(() => import('./screens/Settings'));
const SuperAdmin = lazy(() => import('./screens/SuperAdmin'));


function App() {
  const [user, setUser] = useState(null);
  const [gMIsOpen, setGMIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const checkToken = async () => {
      if (await AsyncLocalStorage.getItem('accessToken')) {
        try {
          const res = await api.getUser();
          setUser(res.data.user);
          ability.update(res.data.ability);
          window.sideby.setUserData(
            {
                fullName: `${res.data.user.name} ${res.data.user.surnames}`,
                id: res.data.user._id,
                // email: res.data.email,
                // phone: 'PHONE',
                // language: 'LANGUAGE',
                // role: 'admin',
            }
        );
        } catch (e) {
          //toast.error('Network Error');
          console.error("Network error");
        }
      }
      setIsLoading(false);
    };
    checkToken();
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AppContext.Provider value={{ user, setUser, gMIsOpen, setGMIsOpen }}>
        <AbilityContext.Provider value={ability}>
          <div className="App">
            {!isLoading ? (
              <Router>
                <Notification />
                {!user ? (
                  <Switch>
                    <Route path="/signup" component={Signup} />
                    <Route path="/forgotpw" component={Forgot} />
                    <Route path="/resetpw/:resetToken" component={Reset} />
                    <Route path="/" component={Login} />
                  </Switch>
                ) : (
                    <div className="platcontainer">
                      {user && <NavBar />}
                      {user && <BottomBar />}
                      {gMIsOpen && <GroupModal />}
                      <div className="maincont">
                        <TopBar />
                        <Suspense fallback={<Spinner />}>
                          <Switch>
                            <Route path="/home" component={Home} />
                            <Route path="/groups/:groupId" component={Group} />
                            <Route path="/calendar" component={Calendar} />
                            <Route path="/settings" component={Settings} />
                            {/* <Route path="/join-group/:randomId" component={JoinGroup} /> */}
                            {ability.can('see', 'AdminPannel') && (
                              <Route path="/admin-pannel" component={SuperAdmin} />
                            )}
                            <Redirect exact from="/" to="/home" />
                            <Redirect exact from="/login" to="/home" />
                            <Redirect exact from="/signup" to="/home" />
                            <Redirect exact from="/resetpw" to="/home" />
                            <Redirect exact from="/forgotpw" to="/home" />
                            <Route path="/" component={NotFound} />
                          </Switch>
                        </Suspense>
                      </div>
                    </div>

                  )}
              </Router>
            ) : (
                <Spinner />
              )}
          </div>
        </AbilityContext.Provider>
      </AppContext.Provider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
