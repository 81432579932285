import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  makeStyles,
  createStyles,
  TextField,
  InputAdornment,
  Icon,
} from '@material-ui/core';
import { Link , useParams, useHistory} from 'react-router-dom';
import api from '../Lib/api';
import "../stylesheets/components/auth/auth.scss";
import eye from '../assets/icons/eye.svg';
import noEye from '../assets/icons/eye-no.svg';
import logo from '../assets/icons/logo-silueta.svg';
import LittleSpinner from "../components/Utils/LittleSpinner";
import ErrorMessage from "../components/Forms/messages/ErrorMsg";

const schema = yup.object().shape({
  password: yup.string().required("Debes escribir una contraseña")
  .min(process.env.REACT_APP_PASSWORD_MIN_LENGTH, 
    `La contraseña debe de tener al menos ${process.env.REACT_APP_PASSWORD_MIN_LENGTH} carácteres`),
  passwordConfirmation: yup.string().required("Debes repetir la contraseña")
     .oneOf([yup.ref('password'), null], "Ambas contraseñas deben de coincidir")
     .min(process.env.REACT_APP_PASSWORD_MIN_LENGTH,
      `La contraseña debe de tener al menos ${process.env.REACT_APP_PASSWORD_MIN_LENGTH} carácteres`)
});

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      width: '100%',
    },
    input: {
      textAlign: 'start',
    },
  })
);

const Reset = () => {
  let history = useHistory();
  let {resetToken} = useParams();
  const classes = useStyles();
  const [showPw, setShowPw] = useState(false);
  const [showConPw, setShowConPw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //Check if the token is valid. If not redirect to login
  useEffect(() => {
    async function check(){
      try{
          await api.checkReset(resetToken);
      }catch(e){
          history.push("/login")
      }}
     check()
  }, [resetToken, history])

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async data => {
    try {
      setLoading(true);
      var body = {}
      body["newPassword"] = data.password;
      await api.resetPassword(resetToken, body);
      setLoading(false);
      history.push("/login")
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="megacontainer">
      <div className="authformcont">
        <form
          className="authform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <img className="auth-image" src={logo} alt="logo" />
          <h1 className="auth-main">Escribe tu nueva contraseña</h1>
          <div className="form-group mt-2">
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  type={showPw ? "text" : "password"}
                  id="outlined-basic"
                  label="Contraseña"
                  variant="outlined"
                  className={classes.formControl}
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon onClick={() => setShowPw(prev => !prev)}>
                          <img src={showPw ? eye : noEye} alt="Button to show the password." />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            /> 
            </div>
            <div className="form-group mt-0">
            <Controller
              name="passwordConfirmation"
              defaultValue=""
              control={control}
              render={props => (
                <TextField
                  {...props}
                  value={props.value}
                  onChange={e => props.onChange(e)}
                  type={showConPw ? "text" : "password"}
                  id="outlined-basic"
                  label="Repetir contraseña"
                  variant="outlined"
                  className={classes.formControl}
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon onClick={() => setShowConPw(prev => !prev)}>
                          <img src={showConPw ? eye : noEye} alt="Eye" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="form-group mt-2 mb-0">
            {loading ? (
              <div className="spinnercont">
                <LittleSpinner color="white" />
              </div>
            ) : (
                <input
                  type="submit"
                  className="authbtn loginbtn"
                  value="Resetear contraseña"
                />
              )}
          </div>
        </form>
        {error && <ErrorMessage >
          Algo ha ido mal. Inténtalo de nuevo.
          </ErrorMessage>}
        <Link to="/login">
          <p className="text-muted mt-2">
            Ir al login
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Reset;
