import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../stylesheets/utils/notifications.scss";

function Notification() {
    return (
        <ToastContainer />
    );
  }
  
  export default Notification;