import { useAppContext } from '../../Lib/appContext';
import { useLocation } from 'react-router-dom';
import burguer from '../../assets/icons/burguer.svg';
import xIcon from '../../assets/icons/x.svg';
import '../../stylesheets/navs/topbar.scss';

const TopNavbar = () => {
  
  const location = useLocation();
  const { user, gMIsOpen, setGMIsOpen } = useAppContext();
  
  return (location.pathname !== '/calendar' ||
  (location.pathname === "/calendar" && window.innerWidth < 998)) && (
    <div className="topbar">
      <img src={gMIsOpen ? xIcon : burguer} alt="menu" onClick={()=> setGMIsOpen(!gMIsOpen)} className="material-icons burguer-icon" />
      <div className="center-col">
        {location.pathname === '/home' && (
          <>
            <span className="mr-2" style={{ fontWeight: 'bold' }}>
              Bienvenido
            </span>
            <span id="division"></span>
            <span className="ml-2">{user.name}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default TopNavbar;
